

























































import Vue from 'vue'

const PROVIDER = {
  MTN: 'mtn-benin',
  MOOV: 'moov-benin'
}

export default Vue.extend({
  props: {
    recipient: {
      type: Object,
      default() {
        return {}
      }
    },
    provider: {
      type: String
    }
  },
  methods: {
    hasProfileName(): boolean {
      return this.provider === PROVIDER.MTN
    },

    getMicroCreditLabel(recipient: any) {
      return recipient.profilename.includes('Subscriber Microcredit Profile')
        ? 'OUI'
        : 'NON'
    },

    getStatusLabel(recipient: any) {
      return ({
        success: 'Actif',
        active: 'Actif',
        failed: 'Inactif)'
      } as Record<string, string>)[recipient.status.toLowerCase()]
    },

    isEligible(recipient: any): boolean {
      return this.getMicroCreditLabel(recipient) === 'OUI'
    }
  },
  computed: {
    providerName(): string {
      if (!this.provider) {
        return ''
      }
      return this.provider === PROVIDER.MTN ? 'MTN' : 'MOOV'
    },
    isActifRecipient(): boolean {
      return (
        this.recipient.status === 'ACTIVE' ||
        this.recipient.status === 'SUCCESS'
      )
    },
    isNotActifRecipient(): boolean {
      if (this.recipient.status === 'FAILED') {
        return true
      }
      if (!this.providerName) {
        return false
      }

      return this.providerName === 'MTN'
        ? this.recipient.status && this.recipient.status !== 'ACTIVE'
        : this.recipient.status && this.recipient.status !== 'SUCCESS'
    }
  }
})
