var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'recipient-info-box',
    'p-2',
    {
      'bg-green-100': _vm.isActifRecipient,
      'bg-red-100': _vm.isNotActifRecipient
    }
  ]},[(_vm.isActifRecipient)?_c('div',{staticClass:"flex flex-col-reverse md:flex-row"},[_c('div',{staticClass:"flex flex-col w-full md:w-1/2"},[_c('div',{staticClass:"py-2 font-bold text-green-300 text-16"},[_c('span',{staticClass:"text-ink-l1"},[_vm._v("Nom :")]),_vm._v(" "+_vm._s(_vm.recipient.lastname)+" ")]),_c('div',{staticClass:"py-2 font-bold text-green-300 text-16"},[_c('span',{staticClass:"text-ink-l1"},[_vm._v("Prénoms :")]),_vm._v(" "+_vm._s(_vm.recipient.firstname)+" ")]),(_vm.hasProfileName())?_c('div',{class:[
          {
            'text-green-300': _vm.isEligible(_vm.recipient),
            'text-red-300': !_vm.isEligible(_vm.recipient)
          },
          'py-2',
          'text-16',
          'font-bold'
        ]},[_c('span',{staticClass:"text-ink-l1"},[_vm._v("Profil microcrédit :")]),_vm._v(" "+_vm._s(_vm.getMicroCreditLabel(_vm.recipient))+" ")]):_vm._e()]),_c('div',{staticClass:"w-full pl-2 md:w-1/2 info-boder"},[_c('div',{staticClass:"py-2 font-bold text-green-300 text-16"},[_c('span',{staticClass:"text-ink-l1"},[_vm._v("OTM :")]),_vm._v(" "+_vm._s(_vm.providerName)+" ")]),_c('div',{staticClass:"py-2 font-bold text-green-300 text-16"},[_c('span',{staticClass:"text-ink-l1"},[_vm._v("Compte Mobile Money :")]),_vm._v(" "+_vm._s(_vm.getStatusLabel(_vm.recipient))+" ")])])]):_vm._e(),(_vm.isNotActifRecipient)?_c('div',{staticClass:"not-active"},[_c('div',{staticClass:"flex justify-center py-4 text-red-300"},[_vm._v(" Cet utilisateur n'est pas actif ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }